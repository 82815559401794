.layout {
  display: flex;
  min-height: 100vh;

  .logo {
    width: 120px;    // 图片宽度
    height: auto;    // 自动调整高度，保持比例
    margin: 16px auto; // 图片上下居中
    display: block;  // 保证图片为块级元素
  }

  .sider {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 220px; // 调整侧边栏宽度
    background-color: #fff; // 浅色背景
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); // 添加轻微阴影
  }

  .ant-menu-item {
    margin: 10px 0; // 每个菜单项上下间距
    padding-left: 50px !important; // 左侧缩进增大
    height: 50px; // 调整菜单项高度
    display: flex;
  }

  .content {
    margin-left: 200px; // 内容区右移与侧边栏保持一致
    background-color: #f5f5f5; // 内容背景色
  }
}
